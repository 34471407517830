<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img :src="require('@/assets/images/logo/logo2.png')" alt="" style="max-width:150px">
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Selamat Datang 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Silakan masuk ke akun Anda
                    </b-card-text>

                    <!-- form -->
                    <b-form
                        class="auth-login-form mt-2"
                        @submit.prevent="submitForm"
                    >
                        <!-- email -->
                        <b-form-group
                            label="Email"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="v$.emailName.$model"
                                :state="validateState(v$.emailName)"
                            />
                            <small class="text-danger" v-for="(error, index) of v$.emailName.$errors" :key="index">{{ error.$message }}</small>
                        </b-form-group>

                        <!-- forgot password -->
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="password">Password</label>
                            </div>
                            <b-input-group
                                class="input-group-merge"
                                :class="v$.password.$errors > 0 ? 'is-invalid':null"
                            >
                                <b-form-input
                                    id="password"
                                    v-model="v$.password.$model"
                                    :state="validateState(v$.password)"
                                    class="form-control-merge"
                                    :type="passwordFieldType"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                        class="cursor-pointer"
                                        :icon="passwordToggleIcon"
                                        @click="togglePasswordVisibility"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger" v-for="(error, index) of v$.password.$errors" :key="index">{{ error.$message }}</small>
                        </b-form-group>

                        <!-- submit buttons -->
                        <b-button
                            type="submit"
                            variant="primary"
                            block
                        >
                            <div v-if="isSubmit">
                                <b-spinner small />
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div v-else>Masuk</div>
                        </b-button>
                    </b-form>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, helpers, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed } from '@vue/composition-api'

export default {
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        clearInterval(g.$store.state.global.idInterval)

        var emailName = ref('')
        var password = ref('')
        
        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            emailName: { 
                required: helpers.withMessage('Email '+requiredMsg, required),
                email: helpers.withMessage('Format Email Salah', email),
            },
            password: { 
                required: helpers.withMessage('Password '+requiredMsg, required)
            }
        }))

        const v$ = useVuelidate(rules, { emailName, password }, {$externalResults, $autoDirty: true})

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const submitForm = async () => {
            isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            // mapping data
            let data = {
                email: emailName.value,
                password: password.value
            };

            $http({
                url: '/login',
                data,
                method: 'post'
            })
                .then(res => {
                    let {token, userDataVal} = res.data.data
                    $cookies.set('token', token)
                    localStorage.setItem('userData', JSON.stringify(userDataVal))

                    //update permission page
                    g.$ability.update(userDataVal.ability)
                    router.push({name: 'home'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Login`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        return {
            validateState, submitForm,
            v$,
            emailName, password, isSubmit
        }
    },
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend, BInputGroup,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton, 
        BFormInvalidFeedback,
        BSpinner,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            sideImg: require('@/assets/images/pages/login-v2.svg')
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            return this.sideImg
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>